import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Media, PortableText } from '@liftfoils/components'
import { SectionHugeImageProps } from '@liftfoils/models'

const Container = styled('div', {
  display: 'grid',
  backgroundColor: '$navy800',

  '& > *': {
    gridArea: '1/1',
  },
})

const Wrapper = styled('div', {
  position: 'relative',
  $py: 'XXL',
  color: 'white',
  display: 'grid',
  gridGap: '$10',

  '@md': {
    gridTemplateColumns: '1.2fr 1fr',
  },
  '@xl': {
    gridTemplateColumns: '800px 1fr',
  },
})

const GradientLayer = styled('div', {
  display: 'grid',
  background: 'linear-gradient(rgba(0,0,0, 0.3), $navy800)',
})

const ContentWrap = styled('div', {
  position: 'relative',
  textAlign: 'center',
  mx: '$containerMargin',
  '@md': {
    maxWidth: '570px',
    mr: '$0',
    textAlign: 'left',
  },
})

const Content = styled('div', {
  display: 'grid',
  gridRowGap: '$9',
  '@md': {
    position: 'sticky',
    top: '$12',
  },
})

const Title = styled('h2', {
  lift_font: 'caps02',
})

const Caption = styled('div', {
  lift_font: 'heading01',
  color: '$white_06',
  '.highlighted': {
    color: 'white',
  },
})

const Description = styled('div', {
  lift_font: 'heading03',
  color: '$white_06',
  '.highlighted': {
    color: 'white',
  },
})

const MediaWrapper = styled('div', {
  overflow: 'hidden',
  width: '100%',
  maxWidth: '75%',
  mx: 'auto',
  '@md': {
    maxWidth: 'initial',
    mx: 'initial',
  },
  '> *': {
    '@md': {
      width: '120%',
    },
    '@xl': {
      width: '100%',
      transform: 'translateX(20%)',
    },
    '@xxl': {
      width: '80%',
    },
  },
})

export const SectionHugeImage: FC<SectionHugeImageProps> = ({
  title,
  caption,
  content,
  image,
  priority,
}) => {
  return (
    <Container>
      <GradientLayer />
      <Wrapper>
        <ContentWrap>
          <Content>
            {title && <Title>{title}</Title>}

            {caption && (
              <Caption>
                <PortableText value={caption} />
              </Caption>
            )}
            {content && (
              <Description>
                <PortableText value={content} />
              </Description>
            )}
          </Content>
        </ContentWrap>
        {image && (
          <MediaWrapper>
            <Media
              {...image}
              layout={'responsive'}
              sizes="(min-width: 740px) 50vw, 75vw"
              priority={priority}
              alt={image.alt ?? ''}
            />
          </MediaWrapper>
        )}
      </Wrapper>
    </Container>
  )
}
